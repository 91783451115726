export default function FormattedContact({ contact }) {
  if (!contact) {
    return null;
  }

  return (
    <div className="flex-col">
      <p className="!mb-0">{contact?.displayName}</p>

      {contact?.position && <p className="!mb-0">{contact?.position}</p>}

      {contact?.phone && <p className="!mb-0">{contact?.phone}</p>}

      {contact?.email && <p className="!mb-0">{contact?.email}</p>}
    </div>
  );
}
