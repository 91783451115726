import React from 'react';
import { Typography } from 'antd';
import TabularList from 'components/TabularList/TabularList';
import { FileList } from 'components/FileList/FileList';
import { getFormattedPhoneNumber } from 'utils';
import dayjs from 'dayjs';
import { DATE_FORMAT_US_SLASH } from 'components/AriaDatePicker/AriaDatePicker';
import { EMDASH } from 'constants';

const { Title } = Typography;

const StaffMembers = ({ application, applicationFiles }) => {
  return (
    <>
      <Title level={3} className="mt-7">
        Staff members
      </Title>
      <TabularList
        data={[
          {
            title: 'First aid/CPR certification documents',
            value: <FileList files={applicationFiles?.filter((f) => f.fileType === 'CERTIFICATION')} />,
            titleRequired: true,
          },
        ]}
      />
      {application?.staff?.map((staffMember, idx) => {
        return (
          <>
            <Title level={4} className="mt-7">
              Staff member {idx + 1} {idx === 0 && '(license holder)'}
            </Title>
            <TabularList
              data={[
                {
                  title: 'Name',
                  value: staffMember.person.displayName,
                  titleRequired: true,
                },
                {
                  title: 'Date of birth',
                  value: dayjs(staffMember.person.dateOfBirth).format(DATE_FORMAT_US_SLASH),
                  titleRequired: true,
                },
                {
                  title: 'Role',
                  value: staffMember.person.position,
                  titleRequired: true,
                },
                {
                  title: 'Present on weekends?',
                  value: staffMember.presentOnWeekends ? 'Yes' : 'No',
                  titleRequired: true,
                },
              ]}
            />
            {staffMember?.certifications.length > 0 &&
              staffMember?.certifications.map((cert) => (
                <>
                  <Title level={5} className="mt-7">
                    Staff member {idx + 1} {idx === 0 && '(license holder)'} {cert?.certificationType?.description}{' '}
                    certification
                  </Title>
                  <TabularList
                    data={[
                      {
                        title: 'Certification type',
                        value:
                          cert?.certificationType?.description != null ? cert?.certificationType?.description : EMDASH,
                        titleRequired: true,
                      },
                      {
                        title: 'Certificate number',
                        value: cert?.number !== null ? cert?.number : EMDASH,
                        titleRequired: true,
                      },
                      {
                        title: 'Certificate issue date',
                        value:
                          cert?.certificationType?.issueDate !== null
                            ? dayjs(cert?.certificationType?.issueDate).format(DATE_FORMAT_US_SLASH)
                            : EMDASH,
                        titleRequired: true,
                      },
                      {
                        title: 'Certificate expiration date',
                        value: dayjs(cert?.certificationType?.expirationDate).format(DATE_FORMAT_US_SLASH),
                        titleRequired: true,
                      },
                      {
                        title: 'Course instructor',
                        value: (
                          <>
                            <p>{cert?.instructor?.displayName}</p>
                            <p>{cert?.instructor?.email}</p>
                            <p>{getFormattedPhoneNumber(cert.instructor.phone)}</p>
                          </>
                        ),
                        titleRequired: true,
                      },
                    ]}
                  />
                </>
              ))}
          </>
        );
      })}
    </>
  );
};

export default StaffMembers;
