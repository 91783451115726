import { message, Spin, Alert } from 'antd';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import { Typography } from 'antd';
import { providerService } from 'services';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { useAsync } from 'react-async-hook';

const { Title, Paragraph } = Typography;

export const PROVIDER_APPLICATION_PROGRAM_TYPES = {
  MCCYN: {
    id: 1,
    name: 'MCCYN',
  },
  AIR_FORCE_HCC: {
    id: 17,
    name: 'Air Force HCC',
  },
  NAVY_HCC: {
    id: 16,
    name: 'Navy HCC',
  },
  ARMY_RESPITE: {
    id: 4,
    name: 'Army Respite',
  },
};

const YOU_ALREADY_APPLIED_FOR_THIS_PROGRAM = 'You already applied for this program.';

export default function SelectProgram() {
  const history = useHistory();
  const { providerId } = useParams();

  // fetch all the care facility applications
  // and figure out which of the PROVIDER_APPLICATION_PROGRAM_TYPES exist already
  const [uniqueProgramIdsThatExist, setUniqueProgramIdsThatExist] = useState(null);
  useAsync(async () => {
    const _applications = await providerService.getCarefacilityApplications(providerId);

    // get unique program ids
    const uniqueProgramIds = new Set();
    _applications.forEach((application) => {
      uniqueProgramIds.add(application.programType.id);
    });

    setUniqueProgramIdsThatExist(Array.from(uniqueProgramIds));
  }, [providerId]);

  const [applicationBeingCreated, setApplicationBeingCreated] = useState(null);
  const createApplication = async (programType) => {
    setApplicationBeingCreated(programType);

    try {
      const newApplication = await providerService.createApplication(providerId, {
        programType: { id: programType },
      });

      message.success('Application created');
      history.push(`/provider/applications/${newApplication?.id}`);
    } catch (error) {
      console.log('Error creating application', error);
      message.error('Failed to create application');
    } finally {
      setApplicationBeingCreated(null);
    }
  };

  if (uniqueProgramIdsThatExist === null) {
    return <Spin className="table m-auto" />;
  }

  return (
    <div>
      {uniqueProgramIdsThatExist.length < 1 && (
        <Alert
          style={{ paddingBottom: '0' }}
          className="mb-6"
          message={<Title level={2}>Your child care location has been saved.</Title>}
          description={
            <>
              <Paragraph>
                Contact Provider Services to make changes:{' '}
                <a
                  href="mailto:providerservices@usa.childcareaware.org"
                  style={{ textDecoration: 'underline', color: '#000000' }}
                >
                  providerservices@usa.childcareaware.org
                </a>
              </Paragraph>
              <Paragraph>You can now apply for a fee assistance program.</Paragraph>
            </>
          }
          type="success"
        />
      )}

      <Title>Choose program</Title>
      <p>Choose a Department of Defense fee assistance program to apply for.</p>

      <Card
        title={
          <Title level={2} className="mt-4">
            MCCYN and MCCYN-PLUS Fee Assistance
          </Title>
        }
        className="mt-9 mb-4"
      >
        {uniqueProgramIdsThatExist.includes(PROVIDER_APPLICATION_PROGRAM_TYPES.MCCYN.id) ? (
          YOU_ALREADY_APPLIED_FOR_THIS_PROGRAM
        ) : (
          <>
            <p>
              Military Child Care in Your Neighborhood (MCCYN) is a weekday fee assistance program that serves military
              families of the U.S. Air Force, U.S. Army, and U.S. Marine Corps. MCCYN-PLUS includes providers that have
              a quality rating from their state’s Quality Rating and Improvement System (QRIS).{' '}
              <ExternalLink
                text="Learn more about MCCYN"
                href="https://www.childcareaware.org/fee-assistancerespite/feeassistancerespiteproviders/feeassistance/"
              />
            </p>

            <p className="font-semibold mt-4">What you’ll need:</p>
            <ul className="list-disc ml-4 mb-4">
              <li>Documentation of your rates</li>
              <li>Information about any discounts that you offer</li>
              <li>QRIS rating details for MCCYN-PLUS</li>
              <li>Accreditation details (if applicable)</li>
            </ul>

            <Button
              type="primary"
              htmlType="submit"
              data-testid="mccynBtn"
              loading={applicationBeingCreated === PROVIDER_APPLICATION_PROGRAM_TYPES.MCCYN.id}
              onClick={() => {
                createApplication(PROVIDER_APPLICATION_PROGRAM_TYPES.MCCYN.id);
              }}
            >
              Apply for MCCYN
            </Button>
          </>
        )}
      </Card>

      <Card
        title={
          <Title level={2} className="mt-4">
            Air Force Home Community Care
          </Title>
        }
        className="mb-4"
      >
        {uniqueProgramIdsThatExist.includes(PROVIDER_APPLICATION_PROGRAM_TYPES.AIR_FORCE_HCC.id) ? (
          YOU_ALREADY_APPLIED_FOR_THIS_PROGRAM
        ) : (
          <>
            <p>
              The Air Force Home Community Care program provides child care for U.S. Air Force Reserve and National
              Guard members assigned to specific installations during drill weekends.
              <ExternalLink
                text="Learn more about Air Force Home Community Care"
                href="https://www.childcareaware.org/fee-assistancerespite/airforcehcc/"
              />
            </p>

            <p className="font-semibold mt-4">What you’ll need:</p>
            <ul className="list-disc ml-4 mb-4">
              <li>Your NAFCC certification</li>
              <li>Your high school diploma</li>
              <li>Your hours of operation </li>
              <li>Staff and resident names and dates of birth</li>
              <li>First aid/CPR certifications, where applicable</li>
              <li>Proof of general liability insurance, with coverage of at least $500,000</li>
            </ul>

            <Button
              type="primary"
              htmlType="submit"
              data-testid="airForceHccBtn"
              loading={applicationBeingCreated === PROVIDER_APPLICATION_PROGRAM_TYPES.AIR_FORCE_HCC.id}
              onClick={() => {
                createApplication(PROVIDER_APPLICATION_PROGRAM_TYPES.AIR_FORCE_HCC.id);
              }}
            >
              Apply for Air Force HCC
            </Button>
          </>
        )}
      </Card>

      <Card
        title={
          <Title level={2} className="mt-4">
            Navy Home Community Care
          </Title>
        }
        className="mb-4"
      >
        {uniqueProgramIdsThatExist.includes(PROVIDER_APPLICATION_PROGRAM_TYPES.NAVY_HCC.id) ? (
          YOU_ALREADY_APPLIED_FOR_THIS_PROGRAM
        ) : (
          <>
            <p>
              The Navy Home Community Care program provides child care for U.S. Navy Reserve members assigned to
              specific installations during drill weekends.{' '}
              <ExternalLink
                text="Learn more about Navy Home Community Care"
                href="https://www.childcareaware.org/fee-assistancerespite/feeassistancerespiteproviders/navy-home-community-care/"
              />
            </p>

            <p className="font-semibold mt-4">What you’ll need:</p>
            <ul className="list-disc ml-4 mb-4">
              <li>Your hours of operation</li>
              <li>Staff and resident names and dates of birth</li>
              <li>First aid/CPR certifications, where applicable</li>
            </ul>

            <Button
              type="primary"
              htmlType="submit"
              data-testid="navyHccBtn"
              loading={applicationBeingCreated === PROVIDER_APPLICATION_PROGRAM_TYPES.NAVY_HCC.id}
              onClick={() => {
                createApplication(PROVIDER_APPLICATION_PROGRAM_TYPES.NAVY_HCC.id);
              }}
            >
              Apply for Navy HCC
            </Button>
          </>
        )}
      </Card>

      <Card
        title={
          <Title level={2} className="mt-4">
            Army Respite Care
          </Title>
        }
        className="mb-4"
      >
        {uniqueProgramIdsThatExist.includes(PROVIDER_APPLICATION_PROGRAM_TYPES.ARMY_RESPITE.id) ? (
          YOU_ALREADY_APPLIED_FOR_THIS_PROGRAM
        ) : (
          <>
            <p>
              The Army Respite program provides child care for U.S. Army families who meet certain criteria.{' '}
              <ExternalLink
                text="Learn more about Army Respite Care"
                href="https://www.childcareaware.org/fee-assistancerespite/military-families/army/afa-program/"
              />
            </p>

            <p className="font-semibold mt-4">What you’ll need:</p>
            <ul className="list-disc ml-4 mb-4">
              <li>Documentation of your hourly rates</li>
            </ul>

            <Button
              type="primary"
              htmlType="submit"
              data-testid="armyRespiteBtn"
              loading={applicationBeingCreated === PROVIDER_APPLICATION_PROGRAM_TYPES.ARMY_RESPITE.id}
              onClick={() => {
                createApplication(PROVIDER_APPLICATION_PROGRAM_TYPES.ARMY_RESPITE.id);
              }}
            >
              Apply for Army Respite Care
            </Button>
          </>
        )}
      </Card>
    </div>
  );
}
