import React from 'react';
import { Typography } from 'antd';
import TabularList from 'components/TabularList/TabularList';

const { Title } = Typography;

const Discounts = ({ application }) => {
  return (
    <>
      <Title level={3} className="mt-7">
        Discounts
      </Title>
      <TabularList
        data={[
          {
            title: 'Do you offer discounts of any kind?',
            value: application?.discounts.length > 0 ? 'Yes' : 'No',
            titleRequired: true,
          },
          ...application?.discounts.map((discount) => ({
            title: `${discount.type.title} discount`,
            value: (
              <>
                {`$${discount.rate}`}
                <br />
                {discount.additionalComments}
              </>
            ),
          })),
        ]}
      />
    </>
  );
};

export default Discounts;
