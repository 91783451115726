import React, { useState, useEffect } from 'react';
import { Typography, Spin } from 'antd';
import Card from 'components/Card/Card';
import { useParams } from 'react-router-dom';
import providerService from 'services/providers.service';
import { PROVIDER_APPLICATION_PROGRAM_TYPES } from 'containers/Provider/SelectProgram/SelectProgram';
import ProgramInfo from './ApplicationDetails/ProgramInfo';
import StaffMembers from './ApplicationDetails/StaffMembers';
import Residents from './ApplicationDetails/Residents';
import Insurance from './ApplicationDetails/Insurance';
import Education from './ApplicationDetails/Education';
import Accreditations from './ApplicationDetails/Accreditations';
import Rates from './ApplicationDetails/Rates';
import Fees from './ApplicationDetails/Fees';
import Discounts from './ApplicationDetails/Discounts';

const { Title } = Typography;

const ApplicationDetails = () => {
  const [loading, setLoading] = useState(null);
  const [application, setApplication] = useState(null);
  const [applicationFiles, setApplicationFiles] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    providerService
      .getApplication(id)
      .then((data) => {
        setApplication(data);
      })
      .catch((error) => {
        console.error('Error fetching application', error);
      })
      .finally(() => {
        setLoading(false);
      });

    providerService
      .getApplicationFiles(id)
      .then((data) => {
        setApplicationFiles(data);
      })
      .catch((error) => {
        console.error('Error fetching application files', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <Spin spinning={loading}>
      <Card
        style={{
          borderTop: '0px',
        }}
      >
        <Title level={2}>Application Details</Title>
        {/* MCCYN & Army Respite */}
        {(application?.programType.id === PROVIDER_APPLICATION_PROGRAM_TYPES.MCCYN.id ||
          application?.programType.id === PROVIDER_APPLICATION_PROGRAM_TYPES.ARMY_RESPITE.id) && (
          <>
            <Accreditations application={application} applicationFiles={applicationFiles} />
            <Rates application={application} applicationFiles={applicationFiles} />
            {/* This section is specific to MCCYN */}
            {application?.programType.id === PROVIDER_APPLICATION_PROGRAM_TYPES.MCCYN.id && (
              <>
                <Fees application={application} />
                <Discounts application={application} />
              </>
            )}
          </>
        )}

        {/* These sections are unique to HCC */}
        {(application?.programType.id === PROVIDER_APPLICATION_PROGRAM_TYPES.AIR_FORCE_HCC.id ||
          application?.programType.id === PROVIDER_APPLICATION_PROGRAM_TYPES.NAVY_HCC.id) && (
          <>
            {/* Program Information */}
            <ProgramInfo application={application} />

            {/* Staff members */}
            <StaffMembers application={application} applicationFiles={applicationFiles} />

            {/* Residents */}
            <Residents application={application} />
          </>
        )}

        {/* These sections are unique to Air Force HCC */}
        {application?.programType.id === PROVIDER_APPLICATION_PROGRAM_TYPES.AIR_FORCE_HCC.id && (
          <>
            {/* Insurance */}
            <Insurance application={application} applicationFiles={applicationFiles} />

            {/* Education */}
            <Education applicationFiles={applicationFiles} />
          </>
        )}
      </Card>
    </Spin>
  );
};

export default ApplicationDetails;
