import { Tooltip } from 'antd';
import { Filter, FilterEdit } from '@carbon/icons-react';

export default function TableHeaderFilterIcon({ filtered, showTooltip }) {
  return (
    <Tooltip title={showTooltip === false ? undefined : 'Filters'}>
      <div>
        {filtered ? (
          <FilterEdit className="align-middle text-blue-600" aria-label="Filter Button" />
        ) : (
          <Filter className="align-middle" aria-label="Filter Button" />
        )}
      </div>
    </Tooltip>
  );
}
