import React from 'react';
import { Typography } from 'antd';
import TabularList from 'components/TabularList/TabularList';
import { FileList } from 'components/FileList/FileList';
import { getFormattedPhoneNumber, formatDollar } from 'utils';
import { EMDASH } from 'constants';

const { Title } = Typography;

const Insurance = ({ application, applicationFiles }) => {
  return (
    <>
      <Title level={3} className="mt-7">
        Insurance
      </Title>
      <TabularList
        data={[
          {
            title: 'Insurance provider',
            value: application?.liabilityInsurance?.issuedBy ? application?.liabilityInsurance?.issuedBy : EMDASH,
            titleRequired: true,
          },
          {
            title: 'Insured amount',
            value: application?.liabilityInsurance?.coverageAmount
              ? formatDollar(application?.liabilityInsurance?.coverageAmount)
              : EMDASH,
            titleRequired: true,
          },
          {
            title: 'Insurance provider phone',
            value: application?.liabilityInsurance?.agentPhone
              ? getFormattedPhoneNumber(application?.liabilityInsurance?.agentPhone)
              : EMDASH,
            titleRequired: true,
          },
          {
            title: 'Proof of insurance',
            value: <FileList files={applicationFiles?.filter((f) => f.fileType === 'INSURANCE')} />,
            titleRequired: true,
          },
        ]}
      />
    </>
  );
};

export default Insurance;
