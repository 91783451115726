import axios from 'axios';
import qs from 'qs';
import config from 'config';
import { setupCache } from './interceptors';

const instance = axios.create({ baseURL: config.API_BASE_URL });
const request = setupCache(instance);

const api = {
  get: async (url, options = {}) => {
    const { data } = await request.get(url, options);
    return data;
  },
  post: async (url, body, params) => {
    const response = await request.post(url, body, { params });
    const { data } = response;
    return data;
  },
  getPaginatedData: async ({ url, params, body, method = 'get' }) => {
    const response = await request({
      method,
      url,
      data: body,
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    const { data } = response;
    return data;
  },
  getGccProviders: async (params, pagination, isPublicSearch) => {
    const body = { ...params };
    if (body.location) {
      body.point = { lat: body.location?.point?.lat, lon: body.location?.point?.lng };
      delete body.location;
    }
    if (!(body.distanceInMiles > 0)) {
      delete body.distanceInMiles;
    }
    if (!(body.ageInMonths > 0) || body.ageInMonths > 13 * 12) {
      delete body.ageInMonths;
    }
    if (!body.facilityTypes || body.facilityTypes?.length === 0) {
      delete body.facilityTypes;
    }
    if (!body.preferredProvider || body.preferredProvider?.length === 0) {
      delete body.facilityTypes;
    }
    body.preferredProvider = body.preferredProvider === true || body.preferredProvider === 'true';
    delete body.placeId;
    const searchUrl = `/provider-search/care-facilities/${!isPublicSearch ? 'private' : 'public'}/search?page=${
      pagination?.page || 0
    }&size=${pagination?.size || 10}`;

    const response = await request.post(searchUrl, body);
    const { data } = response;
    return {
      ...data,
      count: data.totalElements,
      page: body.page,
    };
  },
  getPrograms: async () => {
    const { data } = await request.get(`/common/programs?active=true`);
    return data;
  },
  getSponsors: async () => {
    const { data } = await request.get(`/common/program-sponsors`);
    return data;
  },
  getProgramTypes: async (programSponsorId, componentId, statusId) => {
    const { data } = await request.get(
      `/common/program-types/family?programSponsorId=${1}&componentId=${4}&statusId=${9}`,
    );
    return data;
  },
  getAllProgramTypes: async () => {
    const { data } = await request.get('/common/program-types');
    return data;
  },
  getInstallations: async (programSponsorId) => {
    const { data } = await request.get(`/common/military-installations?programSponsorId=${programSponsorId}`);
    return data;
  },
  getInternalSearchResults: async (params) => {
    const { data } = await request.post(
      `/provider-search/coordinator/${params?.family ? 'family' : 'providers'}/search/criteria?size=${
        params.pageSize
      }&page=${params.current}`,
      params,
    );
    return data;
  },
  getAgeGroups: async () => {
    const { data } = await request.get('/providers/options/40');
    return data;
  },
};
export { request };
export default api;
