import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spin, Tabs, Card, Form, Typography, Row, Col, Select, message } from 'antd';
import PageMeta from 'components/PageMeta/PageMeta';
import ApplicationDetails from './ApplicationDetails';
import { CareLocationDetails } from './CareLocationDetails';
import { DocumentDetails } from './DocumentDetails';
import { BusinessDetails } from './BusinessDetails';
import { formatDate, getFullName } from 'utils';
import { PROVIDER_APPLICATION_PROGRAM_TYPES } from 'containers/Provider/SelectProgram/SelectProgram';
import FormattedAddress from 'components/FormattedAddress/FormattedAddress';
import RadioSelect from 'components/Select/RadioSelect';
import { useAsync } from 'react-async-hook';
import { commonService, providerService } from 'services';
import { ROLES } from 'constants';
import { selectUser } from 'features/auth';
import { startCase } from 'lodash-es';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

const { Paragraph, Title, Link } = Typography;

const ProviderApplication = () => {
  const { id } = useParams();

  const history = useHistory();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const currentUser = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(null);
  const [form] = Form.useForm();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [assigneePlaceholder, setAssigneePlaceholder] = useState('');

  const { result: statusOptions = [] } = useAsync(async () => {
    return commonService.get('/providers/application-status-types/internal', { cache: { interpretHeader: false } });
  }, []);

  const { result: assigneeOptions = [] } = useAsync(async () => {
    return commonService.get(`/users/all?role=${ROLES.PROVIDER_COORDINATOR}`);
  }, []);

  useEffect(() => {
    setLoading(true);
    providerService
      .getApplication(id)
      .then((data) => {
        setApplication(data);
      })
      .catch((error) => {
        console.error('Error fetching application', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (application && statusOptions?.length && assigneeOptions?.length) {
      setSelectedStatus(application?.status?.status?.id);
      setSelectedAssignee(application?.assignedTo?.id);
      setAssigneePlaceholder('Unassigned');
    }
  }, [application, statusOptions, assigneeOptions]);

  useEffect(() => {
    // Check if 'activeTab' exists, and if not, set it to '1'
    if (!searchParams.activeTab) {
      searchParams.activeTab = 'application-details';
      history.replace(`${location.pathname}?${queryString.stringify(searchParams)}`);
    }
  }, [history, location.pathname, searchParams]); // Re-run if location or history changes

  const getProgramNameById = (programId) => {
    const program = Object.values(PROVIDER_APPLICATION_PROGRAM_TYPES).find((program) => program.id === programId);
    return program ? program.name : 'N/A';
  };

  const handleSave = async (assignee) => {
    try {
      setLoading(true);
      setSelectedAssignee(assignee);
      await providerService.assignCoordinator([
        {
          userId: assignee,
          applicationId: id,
        },
      ]);
    } catch (error) {
      console.error('Error assigning application', error);
      message.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      setLoading(true);
      setSelectedAssignee(null);
      await providerService.unAssignCoordinator([{ applicationId: id }]);
    } catch (error) {
      console.error('Error unassigning application', error);
      message.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const statusChanged = async (newStatus) => {
    try {
      setLoading(true);
      setSelectedStatus(newStatus);
      await providerService.updateApplicationStatus(id, newStatus);
    } catch (error) {
      console.error('Error updating application status', error);
      message.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <div className="min-h-screen">
        <PageMeta title="Application Details" />

        {/* Header Section */}

        <Card className="mb-6">
          <Title level={1}>
            {application?.careFacility?.businessLegalName || application?.careFacility?.doingBusinessAs || 'N/A'}
          </Title>

          <Row gutter={[8, 8]} className="mt-4 w-3/4">
            <Col span={8}>
              <Paragraph className="!mb-1">
                <strong>Application ID:</strong> {application?.id}
              </Paragraph>
              <Paragraph className="!mb-1">
                <strong>Submitted:</strong> {formatDate(application?.submittedDate)}
              </Paragraph>
              <Paragraph className="!mb-1">
                <strong>Program:</strong> {getProgramNameById(application?.programType?.id)}
              </Paragraph>
            </Col>

            <Col span={8}>
              <Paragraph className="!mb-1">
                <strong>Care location address:</strong>
                <br />
                {application?.careFacility?.address ? (
                  <FormattedAddress address={application.careFacility.address} />
                ) : (
                  'N/A'
                )}
              </Paragraph>
            </Col>

            <Col span={8}>
              <Paragraph className="!mb-1">
                <strong>Applicant:</strong>{' '}
                {application?.careFacility?.organization?.businessContact?.displayName || 'N/A'}
                <br />
                {application?.careFacility?.organization?.businessContact?.position || 'N/A'}
                <br />
                {application?.careFacility?.organization?.businessContact?.email && (
                  <Link href={`mailto:${application.careFacility.organization.businessContact.email}`}>
                    {application.careFacility.organization.businessContact.email}
                  </Link>
                )}
              </Paragraph>
            </Col>
          </Row>
        </Card>

        {/* Main Section */}
        <div className="flex flex-col lg:flex-row gap-4">
          {/* Left Column */}
          <div className="lg:w-2/3 w-full">
            <Tabs
              activeKey={searchParams.activeTab}
              items={[
                {
                  key: 'application-details',
                  label: 'Application details',
                  children: <ApplicationDetails />,
                },
                {
                  key: 'care-location-details',
                  label: 'Care location details',
                  children: <CareLocationDetails application={application} />,
                },
                {
                  key: 'business-details',
                  label: 'Business details',
                  children: <BusinessDetails application={application} />,
                },
                {
                  key: 'documents',
                  label: 'Documents',
                  children: <DocumentDetails application={application} />,
                },
              ]}
              onChange={(key) => {
                // update activeKey search param
                searchParams.activeTab = key;
                history.replace(`${location.pathname}?${queryString.stringify(searchParams)}`);
              }}
              tabBarStyle={{
                marginBottom: '0px',
                border: 'none',
              }}
            />
          </div>

          {/* Right Column */}
          <div className="lg:w-1/3 w-full">
            <Card className="flex flex-col">
              <h4 className="mb-6 text-[18px]">Review application</h4>
              <Form form={form} layout="vertical" data-testid="provider-application-review-form">
                <Form.Item label="Status" required>
                  <Select
                    name="review-status"
                    options={statusOptions
                      .sort((a, b) => {
                        const order = ['new', 'reviewed', 'incomplete'];
                        return order.indexOf(a.title.toLowerCase()) - order.indexOf(b.title.toLowerCase());
                      })
                      .map((o) => ({ value: o.id, label: startCase(o?.title?.toLowerCase()) }))}
                    value={selectedStatus}
                    onChange={statusChanged}
                    aria-label="Review Status"
                    data-testid="application-assign-status"
                  ></Select>
                </Form.Item>
                <Form.Item label="Assignee" required>
                  <RadioSelect
                    value={selectedAssignee}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    saveText="Save"
                    cancelText="Clear"
                    placeholder={assigneePlaceholder}
                    options={[
                      { label: getFullName(currentUser), value: currentUser?.id },
                      ...(assigneeOptions || [])
                        .filter((assignee) => assignee.id !== currentUser?.id)
                        .map((assignee) => ({ label: getFullName(assignee), value: assignee.id })),
                    ]}
                    data-testid="application-assign-reviewer"
                  />
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ProviderApplication;
