import React, { useState, useRef, useEffect } from 'react';
import { Select, Radio, Button, Space, Divider, Input } from 'antd';
import { Search } from '@carbon/icons-react';

const RadioSelect = ({
  options,
  value,
  onChange,
  onSave,
  onCancel,
  saveText = 'Save',
  cancelText = 'Cancel',
  placeholder = 'Select an option',
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (menuIsOpen) {
      setTimeout(() => searchInputRef?.current?.focus(), 100);
    }
  }, [menuIsOpen]);

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleSave = () => {
    onSave(selectedValue);
    setMenuIsOpen(false);
  };

  const handleCancel = () => {
    setSelectedValue(null);
    onCancel();
    setMenuIsOpen(false);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleDropdownVisibleChange = async (isVisible) => {
    setMenuIsOpen(isVisible);
  };

  const filteredOptions = options?.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

  const renderDropdown = () => (
    <div className="p-3">
      <Input
        ref={searchInputRef}
        placeholder="Search"
        value={searchText}
        onChange={handleSearch}
        prefix={<Search />}
      ></Input>
      <Divider className="my-2" />
      <div className="overflow-y-scroll">
        <Radio.Group onChange={handleRadioChange} value={selectedValue}>
          <Space direction="vertical" className="max-h-36">
            {filteredOptions?.map((option) => (
              <div className="flex items-center" key={option.value}>
                <Radio value={option.value} className="mr-2">
                  <span className="text-sm">{option.label}</span>
                </Radio>
              </div>
            ))}
          </Space>
        </Radio.Group>
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between mt-2">
        <Button onClick={handleCancel} size="middle">
          {cancelText}
        </Button>
        <Button onClick={handleSave} type="primary" size="middle" disabled={selectedValue == null}>
          {saveText}
        </Button>
      </div>
    </div>
  );

  return (
    <Select
      value={value}
      onChange={onChange}
      dropdownRender={renderDropdown}
      placeholder={placeholder}
      open={menuIsOpen}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      options={options}
    ></Select>
  );
};

export default RadioSelect;
