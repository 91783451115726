import React from 'react';
import { Typography } from 'antd';
import TabularList from 'components/TabularList/TabularList';
import { FileList } from 'components/FileList/FileList';

const { Title } = Typography;

const Rates = ({ application, applicationFiles }) => {
  return (
    <>
      <Title level={3} className="mt-7">
        Rates
      </Title>
      <TabularList
        data={[
          ...(application?.respiteRate
            ? [
                {
                  title: 'Hourly rate per child',
                  value: `$${application?.respiteRate}`,
                  titleRequired: true,
                },
              ]
            : []),
          {
            title: 'Rate sheet',
            value: <FileList files={applicationFiles?.filter((f) => f.fileType === 'RATES')} />,
            titleRequired: true,
          },
        ]}
      />
    </>
  );
};

export default Rates;
