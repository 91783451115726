import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import styles from './Table.module.less';

export default function GccTable({
  className,
  size = 'normal',
  rowKey = 'id',
  allColumns,
  data,
  getTableData,
  expandable,
  expandedRowRender,
  loading: tableDataLoading,
  pagination,
  pageSize,
  rowSelection,
  hideEmptyBox,
  hideExpandColumn,
  xsCols = 1,
  smCols = 2,
  mdCols = 3,
  lgCols = 4,
  xlCols = 5,
  xxlCols = undefined,
  footer,
  title,
  rowClassName,
  expandedRowClassName,
  columnProps,
  bordered,
  showColSeparator,
  showRowSeparator,
  ...rest
}) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({
    current: 1,
    pageSize,
    total: 0,
    data: data,
    sortOrder: undefined,
    sortColumn: undefined,
  });

  const getData = useCallback(
    async (params) => {
      if (!getTableData) return;
      try {
        setLoading(true);
        const data = await getTableData(params);
        setTableData(data);
      } catch (error) {
        newrelic.noticeError(error);
        setTableData((v) => ({ ...v, data: [] }));
      } finally {
        setLoading(false);
      }
    },
    [getTableData],
  );

  useEffect(() => {
    getData({});
  }, [getData]);

  const handleTableChange = useCallback(
    async (pagination, filters, sorter) => {
      getData({ ...pagination, sortColumn: sorter.field, sortOrder: sorter.order });
    },
    [getData],
  );

  // Adds aria-label to the checkbox in the table
  const customGetCheckboxProps = (record) => {
    const _rowIdentifier = record?.name || record?.title || record?.id || record?.key;

    return {
      'aria-label': `Select row${_rowIdentifier ? ` ${_rowIdentifier}` : ''}`,
    };
  };

  // Merge custom rowSelection with the one passed into the wrapper
  const mergedRowSelection = rowSelection
    ? {
        ...rowSelection, // Preserve any passed-in rowSelection props
        getCheckboxProps: rowSelection?.getCheckboxProps ? rowSelection.getCheckboxProps : customGetCheckboxProps,
      }
    : null;

  return (
    <div className={classNames(styles.table, className)}>
      <Table
        className={classNames(
          { hideEmptyBox },
          {
            large: size === 'large',
            'col-separator': showColSeparator,
            separators: showRowSeparator,
            'hide-expand-col': hideExpandColumn,
          },
        )}
        loading={tableDataLoading || loading}
        columns={allColumns}
        dataSource={data || tableData.data}
        pagination={
          pagination !== undefined
            ? { hideOnSinglePage: true, ...pagination }
            : { hideOnSinglePage: true, ...tableData.pagination }
        }
        rowKey={rowKey}
        onChange={handleTableChange}
        footer={footer}
        title={title}
        rowSelection={mergedRowSelection}
        rowClassName={rowClassName}
        bordered={bordered}
        {...rest}
        scroll={{ x: 'max-content', ...rest.scroll }}
      />
    </div>
  );
}

GccTable.propTypes = {
  allColumns: PropTypes.array,
  className: PropTypes.string,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  lgCols: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  mdCols: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  rowSelection: PropTypes.any,
  smCols: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  xlCols: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  xsCols: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  xxlCols: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  pageSize: PropTypes.number,
  getTableData: PropTypes.func,
  rowClassName: PropTypes.func,
  hideEmptyBox: PropTypes.bool,
  hideExpandColumn: PropTypes.bool,
  footer: PropTypes.any,
  title: PropTypes.any,
  expandable: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  bordered: PropTypes.bool,
  showColSeparator: PropTypes.bool,
  columnProps: PropTypes.object,
};
GccTable.defaultProps = {
  className: '',
  hideEmptyBox: false,
  bordered: false,
  showColSeparator: true,
  hideExpandColumn: false,
  columnProps: {},
  pageSize: 10,
};
