import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import TabularList from 'components/TabularList/TabularList';
import providerService from 'services/providers.service';

const { Title } = Typography;

const Fees = ({ application }) => {
  const [registrationFee, setRegistrationFee] = useState(null);

  useEffect(() => {
    providerService.getFeeRates(application.id).then((data) => {
      setRegistrationFee(data.find((fee) => fee.type.title === 'Registration Fee'));
    });
  }, [application.id]);

  return (
    <>
      <Title level={3} className="mt-7">
        Fees and included costs
      </Title>
      <TabularList
        data={[
          {
            title:
              'Do you charge a registration fee that does not include the cost of deposits, supplies, activities, transportation, etc?',
            value: registrationFee ? 'Yes' : 'No',
            titleRequired: true,
          },
          ...(registrationFee
            ? [
                {
                  title: 'Registration fee',
                  value: (
                    <>
                      {`$${registrationFee?.amount} per ${registrationFee?.chargedBy.description.toLowerCase()} ${
                        registrationFee?.frequency.description === 'Annual' ? 'annually' : 'initially'
                      }`}
                      <br />
                      {registrationFee?.description}
                    </>
                  ),
                  titleRequired: true,
                },
              ]
            : []),
          {
            title: 'Lunch included',
            value: application?.lunchIncluded ? 'Yes' : 'No',
            titleRequired: true,
          },
          {
            title: 'Formula included',
            value: application?.formulaFeeIncluded ? 'Yes' : 'No',
            titleRequired: true,
          },
        ]}
      />
    </>
  );
};

export default Fees;
