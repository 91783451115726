import React from 'react';
import { Typography } from 'antd';
import TabularList from 'components/TabularList/TabularList';
import { FileList } from 'components/FileList/FileList';

const { Title } = Typography;

const Education = ({ applicationFiles }) => {
  return (
    <>
      <Title level={3} className="mt-7">
        Education
      </Title>
      <TabularList
        data={[
          {
            title: 'High school diploma, GED, or self-certification.',
            value: <FileList files={applicationFiles?.filter((f) => f.fileType === 'DIPLOMA')} />,
            titleRequired: true,
          },
          {
            title: 'Continuing education documents',
            value: <FileList files={applicationFiles?.filter((f) => f.fileType === 'EDUCATION')} />,
          },
        ]}
      />
    </>
  );
};

export default Education;
