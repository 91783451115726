import React from 'react';
import { Typography } from 'antd';
import TabularList from 'components/TabularList/TabularList';
import { EMDASH } from 'constants';

const { Title } = Typography;

const ProgramInfo = ({ application }) => {
  return (
    <>
      <Title level={3}>Program information</Title>
      <TabularList
        data={[
          {
            title: 'Invited to apply',
            value: application?.hccInvite ? 'Yes' : 'No',
            titleRequired: true,
          },
          {
            title: 'Age groups served',
            value:
              application?.ageGroups.length > 0
                ? application?.ageGroups?.map((group) => (
                    <>
                      {group.description}
                      <br />
                    </>
                  ))
                : EMDASH,
            titleRequired: true,
          },
          {
            title: 'Services offered Saturdays and Sundays 6:00am-6:00pm?',
            value: application?.hccEligible ? 'Yes' : 'No',
            titleRequired: true,
          },
        ]}
      />
    </>
  );
};

export default ProgramInfo;
