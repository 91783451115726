import { message, Spin, Typography } from 'antd';
import Card from 'components/Card/Card';
import { FileList } from 'components/FileList/FileList';
import FormattedAddress from 'components/FormattedAddress/FormattedAddress';
import FormattedContact from 'components/FormattedContact/FormattedContact';
import TabularList from 'components/TabularList/TabularList';
import { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { providerService } from 'services';
import { PROVIDER_FILE_TYPES } from 'services/providers.service';

const { Title } = Typography;

export function BusinessDetails({ application }) {
  const [careFacility, setCareFacility] = useState(null);
  const organization = application?.careFacility?.organization;

  useAsync(async () => {
    if (application?.careFacility?.id !== undefined) {
      try {
        const _careFacility = await providerService.getCenterById(Number(application?.careFacility?.id));
        setCareFacility(_careFacility);
      } catch (error) {
        console.log('Error fetching care facility', error);
        message.error('Unable to get care facility information', 5);
      }
    }
  }, [application]);

  // Fetch organization files
  const [orgFiles, setOrgFiles] = useState(null);
  useAsync(async () => {
    if (application?.careFacility?.orgId !== undefined) {
      try {
        const _orgFiles = await providerService.getOrgFiles(application?.careFacility?.orgId);
        setOrgFiles(_orgFiles);
      } catch (error) {
        console.error('Error fetching organization files', error);
        message.error('Something went wrong');
      }
    }
  }, [application?.careFacility?.orgId]);

  const _w9Files = orgFiles?.filter((file) => file?.fileType === PROVIDER_FILE_TYPES.W9.code);
  const _voidedCheckOrBankLetterFiles = orgFiles?.filter(
    (file) => file?.fileType === PROVIDER_FILE_TYPES.DIRECT_DEPOSIT.code,
  );

  return (
    <Card style={{ borderTop: '0px' }}>
      <Title
        level={2}
        style={{
          margin: '0px',
        }}
      >
        Business details
      </Title>

      <Spin spinning={careFacility === null}>
        {careFacility && (
          <>
            <Title level={3} className="mt-8">
              Business information
            </Title>
            <TabularList
              data={[
                {
                  title: 'Business legal name',
                  titleRequired: true,
                  value: organization?.businessLegalName,
                },
                {
                  title: 'Business mailing address',
                  titleRequired: true,
                  value: <FormattedAddress address={organization?.address} />,
                },
                {
                  title: 'Business phone',
                  titleRequired: true,
                  value: (
                    <>
                      {organization?.businessPhone}
                      {organization?.businessPhoneExt && <span> x{organization?.businessPhoneExt}</span>}
                    </>
                  ),
                },
                {
                  title: 'Tax classification',
                  titleRequired: true,
                  value: organization?.taxClassification?.title,
                },
                {
                  title: 'TIN',
                  titleRequired: true,
                  value: organization?.taxIdNumber,
                },
                {
                  title: 'Business contact',
                  titleRequired: true,
                  value: <FormattedContact contact={organization?.businessContact} />,
                },
                {
                  title: 'W-9',
                  titleRequired: true,
                  value: <FileList files={_w9Files} />,
                },
              ]}
            />

            <Title level={3} className="mt-8">
              Payment details
            </Title>
            <TabularList
              data={[
                {
                  title: 'Payment method',
                  titleRequired: true,
                  value: organization?.banking?.directDeposit ? 'Direct deposit' : 'Check',
                },
                ...(organization?.banking?.directDeposit
                  ? [
                      {
                        title: 'Routing number',
                        titleRequired: true,
                        value: organization?.banking?.routingNumber,
                      },
                      {
                        title: 'Account number',
                        titleRequired: true,
                        value: organization?.banking?.accountNumber,
                      },
                    ]
                  : []),
                {
                  title: 'Make check payable to',
                  titleRequired: true,
                  value: organization?.banking?.paymentName,
                },
                ...(organization?.banking?.directDeposit
                  ? [
                      {
                        title: 'Voided check or bank letter',
                        titleRequired: true,
                        value: <FileList files={_voidedCheckOrBankLetterFiles} />,
                      },
                    ]
                  : []),
              ]}
            />
          </>
        )}
      </Spin>
    </Card>
  );
}
