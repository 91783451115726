import React, { useCallback, useMemo } from 'react';
import LayoutPage from 'components/Layout';
import { Switch } from 'react-router-dom';
import ProtectedRoute from 'components/ProtectedRoute';
import LoadingPage from 'components/LoadingPage';
import AddUser from './AddUser';
import Users from './Users';
import { UserSettings } from '@carbon/icons-react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectActiveRole, selectRoles } from 'features/auth';
import { ROLES } from 'constants';
import { getInternalMenuItems } from 'containers/Internal/Internal';
import Space from 'components/Space/Space';
import { Button } from 'antd';

const rootBreadcrumb = { text: 'Admin', to: '/admin' };
const addUserRegex = /^\/admin\/add-user(.*)$/;
const adminReviewAppRegex = /^\/admin\/review\/application(.*)$/;
const breadcrumbs = [
  [/\/admin\/?(users|providers|families|organizations)?$/, [rootBreadcrumb]],
  ['/admin/profile', [rootBreadcrumb, { text: 'Profile', to: 'admin/profile' }]],
  [addUserRegex, [rootBreadcrumb, { text: 'Add User', to: 'admin/add-user' }]],
  [/^\/admin\/update-user\/(.*)$/, [rootBreadcrumb, { text: 'Update User', to: 'admin/update-user' }]],
  ['/admin/messages', [rootBreadcrumb, { text: 'Messages', to: 'admin/messages' }]],
  [adminReviewAppRegex, [rootBreadcrumb, { text: 'Review', to: 'admin/review/application' }]],
];
export default function Admin(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const activeRole = useSelector(selectActiveRole);
  const roles = useSelector(selectRoles);

  const menuOptions = useMemo(() => {
    const _options = getAdminMenuOptions();

    if (
      [ROLES.PROVIDER_COORDINATOR, ROLES.PROVIDER_MANAGER, ROLES.FAMILY_COORDINATOR, ROLES.FAMILY_MANAGER].some(
        (_role) => roles.includes(_role),
      )
    ) {
      return [...getInternalMenuItems({ activeRole, dispatch, history }), ..._options];
    }
    return _options;
  }, [activeRole, dispatch, history, roles]);

  const _logout = useCallback(async () => {
    dispatch(logout.pending());
  }, [dispatch]);

  return (
    <LayoutPage
      headerIcons={
        <Button
          type="text"
          style={{
            color: '#FFFFFF',
            textDecoration: 'underline',
          }}
          onClick={_logout}
        >
          <Space>Sign out</Space>
        </Button>
      }
      menuOptions={menuOptions}
      breadcrumbs={breadcrumbs}
    >
      <React.Suspense fallback={<LoadingPage />}>
        <Switch>
          {/* Order is important */}
          <ProtectedRoute exact path="/admin/profile" message="Navigated to Admin Dashboard">
            <AddUser key="profile" self />
          </ProtectedRoute>
          <ProtectedRoute exact path="/admin/add-user" message="Navigated to Admin Dashboard">
            <AddUser key="add-user" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/admin/users" message="Navigated to Admin Dashboard">
            <Users key="users" />
          </ProtectedRoute>

          <ProtectedRoute exact path="/admin/update-user/:email" message="Navigated to Admin Dashboard">
            <AddUser />
          </ProtectedRoute>
          <ProtectedRoute exact path="/admin/:tab?" message="Navigated to Admin Dashboard">
            <Redirect to="/admin/users" />
          </ProtectedRoute>

          <ProtectedRoute message="No matching route.">
            <h3>No Content yet </h3>
          </ProtectedRoute>
        </Switch>
      </React.Suspense>
    </LayoutPage>
  );
}

export function getAdminMenuOptions() {
  return [
    {
      label: 'Users',
      icon: UserSettings,
      to: '/admin',
      match: ['/admin', '/admin/users', /^\/admin\/add-user(.*)$/, /^\/admin\/update-user\/(.*)$/],
    },
    // {
    //   label: 'Profile',
    //   icon: Identification,
    //   to: '/admin/profile',
    //   match: ['/admin/profile'],
    // },
    // {
    //   label: 'Messages',
    //   icon: BiEnvelope,
    //   to: '/admin/messages',
    //   match: ['/admin/messages'],
    // },
  ];
}
