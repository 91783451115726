import React from 'react';
import { Typography } from 'antd';
import TabularList from 'components/TabularList/TabularList';
import { FileList } from 'components/FileList/FileList';
import dayjs from 'dayjs';
import { DATE_FORMAT_US_SLASH } from 'components/AriaDatePicker/AriaDatePicker';

const { Title } = Typography;

const Accreditations = ({ application, applicationFiles }) => {
  return (
    <>
      <Title level={3}>Accreditations</Title>
      <TabularList
        data={[
          {
            title:
              'Nationally accredited, in the process of renewal or self study with an  accrediting agency, or do you have eligible degrees, transcripts, or  awards?',
            value: application?.accreditations.length > 0 ? 'Yes' : 'No',
            titleRequired: true,
          },
          ...(application?.accreditations.length !== 0
            ? [
                {
                  title: 'Accredidation documents',
                  value: <FileList files={applicationFiles?.filter((f) => f.fileType === 'ACCREDITATION')} />,
                },
              ]
            : []),
        ]}
      />
      {application?.accreditations.length > 0 &&
        application?.accreditations.map((cred, idx) => (
          <>
            <Title level={4} className="mt-7">
              Accreditation {idx + 1}
            </Title>
            <TabularList
              data={[
                {
                  title: 'Accrediting body',
                  value: cred.type.description,
                  titleRequired: true,
                },
                {
                  title: 'Credential number',
                  value: cred.accreditationNumber,
                },
                {
                  title: 'Start date',
                  value: dayjs(cred.startDate).format(DATE_FORMAT_US_SLASH),
                },
                {
                  title: 'End date',
                  value: cred.neverExpires ? 'Does not expire' : dayjs(cred.endDate).format(DATE_FORMAT_US_SLASH),
                },
              ]}
            />
          </>
        ))}
    </>
  );
};

export default Accreditations;
