import { Launch } from '@carbon/icons-react';
import { message, Spin } from 'antd';
import { EMDASH } from 'constants';
import { useState } from 'react';
import { filesService } from 'services';

export const FileList = ({ files }) => {
  const [previewLoading, setPreviewLoading] = useState(null);

  if (!files || files.length === 0) {
    return EMDASH;
  }

  return (
    <div className="flex flex-col gap-2">
      {files?.map((file) => {
        const fileId = file.uid || file.id;

        return (
          <Spin spinning={previewLoading === fileId} key={fileId}>
            <button
              onClick={(e) => {
                e.preventDefault();

                showFilePreview(file, setPreviewLoading);
              }}
              aria-label={`Preview ${file.name}`}
              className="underline text-primary p-0 cursor-pointer text-left leading-relaxed"
            >
              <Launch className="shrink-0 align-middle mr-1 mt-[-1px] ml-[-1px]" />

              {file.name}
            </button>
          </Spin>
        );
      })}
    </div>
  );
};

export const showFilePreview = async (file, setLoading) => {
  const fileId = file.uid || file.id;
  setLoading?.(fileId);

  try {
    //Get file content data when clicked
    const fileData = await filesService.getFileContents(fileId);
    const url = `data:${fileData.contentType};base64,${fileData.contents}`;

    if (fileData) {
      setLoading?.(null);

      const _htmlContent = fileData.contentType.startsWith('image/')
        ? `<html>
            <body style="margin:0;display:flex;align-items:center;justify-content:center;background-color:black;">
              <img src="${url}" style="max-width:100%; max-height:100%;" alt="${file.name} - preview" />
            </body>
          </html>`
        : `<html>
            <body style="margin:0;">
              <object data="${url}" type="application/pdf" width="100%" height="100%">
              </object>
            </body>
          </html>`;

      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(_htmlContent);
      } else {
        // If the popup was blocked, show a Modal
        message.error('Please allow popups for this site to view the file preview');
      }
    } else {
      message.error(`Unable to retrieve preview data for file: ${file.name}`);
      console.log('Error while retrieving preview data', fileData);
    }
  } catch (e) {
    setLoading?.(null);

    message.error(`Unable to retrieve preview data for file: ${file.name}`);
    console.error('Error while generating preview', e);
  }
};
