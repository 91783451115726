import { Button, message, Spin, Typography } from 'antd';
import Card from 'components/Card/Card';
import { PROVIDER_APPLICATION_PROGRAM_TYPES } from 'containers/Provider/SelectProgram/SelectProgram';
import { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { providerService } from 'services';
import { MccynDocumentDetails } from './DocumentDetails/MccynDocumentDetails';
import { ArmyRespiteDocumentDetails } from './DocumentDetails/ArmyRespiteDocumentDetails';
import { NavyHccDocumentDetails } from './DocumentDetails/NavyHccDocumentDetails';
import { AirForceHccDocumentDetails } from './DocumentDetails/AirForceHccDocumentDetails';
import { Spacer } from 'components/Space/Space';

const { Title } = Typography;

export function DocumentDetails({ application }) {
  const [allFilesDownloading, setAllFilesDownloading] = useState(false);

  // Fetch application files
  const [applicationFiles, setApplicationFiles] = useState(null);
  useAsync(async () => {
    if (application?.id !== undefined) {
      try {
        const _applicationFiles = await providerService.getApplicationFiles(application?.id);
        setApplicationFiles(_applicationFiles);
      } catch (error) {
        console.error('Error fetching application files', error);
        message.error('Something went wrong');
      }
    }
  }, [application]);

  // Fetch care facility files
  const [careFacilityFiles, setCareFacilityFiles] = useState(null);
  useAsync(async () => {
    if (application !== null && application?.careFacility?.id) {
      try {
        const _files = await providerService.getCareFacilityFiles(application?.careFacility?.id);
        setCareFacilityFiles(_files);
      } catch (error) {
        console.log('Error fetching care facility files', error);
        message.error('Something went wrong');
      }
    }
  }, [application?.careFacility?.id]);

  // Fetch organization files
  const [orgFiles, setOrgFiles] = useState(null);
  useAsync(async () => {
    if (application?.careFacility?.orgId !== undefined) {
      try {
        const _orgFiles = await providerService.getOrgFiles(application?.careFacility?.orgId);
        setOrgFiles(_orgFiles);
      } catch (error) {
        console.error('Error fetching organization files', error);
        message.error('Something went wrong');
      }
    }
  }, [application?.careFacility?.orgId]);

  if (applicationFiles === null || careFacilityFiles === null || orgFiles === null) {
    return <Spin />;
  }

  return (
    <Card style={{ borderTop: '0px' }}>
      <div className="block sm:flex items-center">
        <Title
          level={2}
          style={{
            margin: '0px',
          }}
        >
          Documents
        </Title>

        <Spacer />

        <Button
          className="mt-2 sm:mt-0"
          onClick={async () => {
            setAllFilesDownloading(true);

            try {
              const _zipFileByteArray = await providerService.downloadAllApplicationFiles(application?.id);

              // Create a Blob from the response data
              const blob = new Blob([_zipFileByteArray], { type: 'application/zip' });
              const urlObject = URL.createObjectURL(blob);

              // Create a temporary link element to trigger the download
              const a = document.createElement('a');
              a.href = urlObject;
              a.download = `${application?.careFacility?.businessLegalName}_${application?.id}_documents.zip`; // Set the file name
              document.body.appendChild(a);
              a.click(); // Trigger the download
              a.remove(); // Clean up the DOM

              URL.revokeObjectURL(urlObject); // Revoke the temporary object URL
            } catch (error) {
              console.error('Error downloading all files', error);
              message.error('Something went wrong');
            } finally {
              setAllFilesDownloading(false);
            }
          }}
          loading={allFilesDownloading}
        >
          Download all documents
        </Button>
      </div>

      {/* MCCYN */}
      {application?.programType?.id === PROVIDER_APPLICATION_PROGRAM_TYPES.MCCYN.id && (
        <MccynDocumentDetails
          applicationFiles={applicationFiles}
          careFacilityFiles={careFacilityFiles}
          orgFiles={orgFiles}
        />
      )}

      {/* Army Respite */}
      {application?.programType?.id === PROVIDER_APPLICATION_PROGRAM_TYPES.ARMY_RESPITE.id && (
        <ArmyRespiteDocumentDetails
          applicationFiles={applicationFiles}
          careFacilityFiles={careFacilityFiles}
          orgFiles={orgFiles}
        />
      )}

      {/* Navy HCC */}
      {application?.programType?.id === PROVIDER_APPLICATION_PROGRAM_TYPES.NAVY_HCC.id && (
        <NavyHccDocumentDetails
          applicationFiles={applicationFiles}
          careFacilityFiles={careFacilityFiles}
          orgFiles={orgFiles}
        />
      )}

      {/* Air Force HCC */}
      {application?.programType?.id === PROVIDER_APPLICATION_PROGRAM_TYPES.AIR_FORCE_HCC.id && (
        <AirForceHccDocumentDetails
          applicationFiles={applicationFiles}
          careFacilityFiles={careFacilityFiles}
          orgFiles={orgFiles}
        />
      )}
    </Card>
  );
}
