import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion, useCycle } from 'framer-motion';

import styles from './HomePageElements.module.less';
import { breakpointsContext } from 'utils';
import { use100vh } from 'react-div-100vh';

const variants = {
  initial: { opacity: 0, x: '200%', rotate: 180 },
  active: { opacity: 1, x: 0, rotate: 45 },
};
// const elementsVariants = {
//   hidden: { y: '200%' },
//   visible: { y: 0 },
// };

export default function Tiles({ screen }) {
  const [visible, setVisible] = useState(false);
  const breakpoints = useContext(breakpointsContext);
  const height = use100vh();
  const duration = 10;
  const [start, cycleStart] = useCycle(false, true);
  const [smallSquareX, smallSquareY, smallSquareAngle] = start ? [0, 0, 0] : [-20, 50, 24];
  const [smallCircleX, smallCircleY] = !start ? [0, 0] : [50, 50];
  const [largeCircleX, largeCircleY] = !start ? [0, 0] : [-30, -30];
  const [smallTriangleX, smallTriangleY] = start ? [0, 50] : [0, 0];
  let smallCircleToLeft = { top: '15em', left: '14em' };
  let smallTriangleToLeft = { top: '30em', left: '3em' };
  let smallSquareToLeft = { top: '35em', left: '22em' };
  if (breakpoints.md) {
    smallCircleToLeft = { top: height - 400 || 100, left: '5em' };
    smallTriangleToLeft = { top: height - 300, left: '35em' };
    smallSquareToLeft = { top: height - 200, left: '22em' };
  }
  if (breakpoints.xl) {
    smallCircleToLeft = { top: height - 300 || 100, left: '45em' };
    smallTriangleToLeft = { top: height - 500, left: '40em' };
    smallSquareToLeft = { top: height - 200, left: '27em' };
  }
  useEffect(() => {
    setVisible(true);
  }, []);

  useEffect(() => {
    const id = setInterval(cycleStart, duration * 1000);
    return () => clearInterval(id);
  }, [cycleStart]);

  return (
    <div className={styles.homePageElements}>
      <motion.div
        className={classNames(styles.rect, styles.rectLarge)}
        animate={{ x: largeCircleX, y: largeCircleY, ease: 'linear' }}
        transition={{ duration, ease: 'easeOut' }}
      />
      <motion.div
        animate={visible ? 'active' : 'initial'}
        variants={variants}
        transition={{ duration: 0.5, delay: 0.1, ease: 'easeOut' }}
        className={classNames(styles.rect, styles.rectMedium)}
      >
        {screen === 'xs' && (
          <motion.img
            animate={visible ? { x: 0, rotate: -45 } : { x: 0, rotate: 45 }}
            // variants={variants}
            transition={{ duration: 0.5, delay: 0.2, ease: 'easeOut' }}
            className="circle"
            src="/images/icon_provider.png"
            alt="pointer"
          />
        )}
      </motion.div>
      <motion.div
        animate={visible ? 'active' : 'initial'}
        variants={variants}
        transition={{ duration: 0.5, delay: 0.2, ease: 'easeOut' }}
        className={classNames(styles.rect, styles.rectSmall)}
      />
      {/* {screen !== 'xs' && (
        <motion.div
          className="elements"
          animate={visible ? 'visible' : 'hidden'}
          variants={elementsVariants}
          transition={{ duration: 0.5, delay: 0.3 }}
        />
      )} */}

      <motion.div
        className="w-20 h-20 rounded-xl absolute shadow-lg"
        style={{ background: '#3897FA' }}
        animate={{ x: smallSquareX, y: smallSquareY, ease: 'linear', rotate: smallSquareAngle }}
        transition={{ duration }}
        initial={smallSquareToLeft}
        key={`square-${breakpoints.screen}-${height}`}
      />
      <motion.div
        className="w-28 h-28 rounded-full bg-primary absolute shadow-lg"
        animate={{ x: smallCircleX, y: smallCircleY, ease: 'linear' }}
        transition={{ duration }}
        initial={smallCircleToLeft}
        key={`circle-${breakpoints.screen}-${height}`}
      />

      <motion.div
        className="w-28 h-28 absolute"
        animate={{ x: smallTriangleX, y: smallTriangleY, ease: 'linear' }}
        transition={{ duration }}
        initial={smallTriangleToLeft}
        key={`triangle-${breakpoints.screen}-${height}`}
      >
        <img src="/images/triangle.svg" alt="Triangle" className="w-24 h-24" />
      </motion.div>
    </div>
  );
}

Tiles.propTypes = {
  screen: PropTypes.string,
};

Tiles.defaultProps = {
  screen: 'sm',
};
