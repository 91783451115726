import React from 'react';
import { Typography } from 'antd';
import TabularList from 'components/TabularList/TabularList';
import dayjs from 'dayjs';
import { DATE_FORMAT_US_SLASH } from 'components/AriaDatePicker/AriaDatePicker';

const { Title } = Typography;

const Residents = ({ application }) => {
  return (
    <>
      <Title level={3} className="mt-7">
        Residents
      </Title>
      <TabularList
        data={[
          {
            title: 'Adults over the age of 18 living at the facility?',
            value: application?.adultResidentsPresent ? 'Yes' : 'No',
            titleRequired: true,
          },
          {
            title: 'Children under the age of 8 living at the facility?',
            value: application?.childResidentsPresent ? 'Yes' : 'No',
            titleRequired: true,
          },
        ]}
      />
      {/* Adult Residents */}
      {application?.adultResidentsPresent &&
        application?.residents.length > 0 &&
        application?.residents.map((res, idx) => (
          <>
            <Title level={4} className="mt-7">
              Adult resident {idx + 1}
            </Title>
            <TabularList
              data={[
                {
                  title: 'Name',
                  value: res.person.displayName,
                  titleRequired: true,
                },
                {
                  title: 'Date of birth',
                  value: dayjs(res.dateOfBirth).format(DATE_FORMAT_US_SLASH),
                  titleRequired: true,
                },
                {
                  title: 'Relationship to the provider',
                  value: res.relationship,
                  titleRequired: true,
                },
              ]}
            />
          </>
        ))}
      {/* Child Residents */}
      {application?.childResidentsPresent &&
        application?.childResidents.length > 0 &&
        application?.childResidents.map((res, idx) => (
          <>
            <Title level={4} className="mt-7">
              Child resident {idx + 1}
            </Title>
            <TabularList
              data={[
                {
                  title: 'Name',
                  value: res.person.displayName,
                  titleRequired: true,
                },
                {
                  title: 'Date of birth',
                  value: dayjs(res.dateOfBirth).format(DATE_FORMAT_US_SLASH),
                  titleRequired: true,
                },
              ]}
            />
          </>
        ))}
    </>
  );
};

export default Residents;
