import { Email, Password, Reset, UserFollow } from '@carbon/icons-react';
import { Button, Input, message, Tooltip } from 'antd';
import classNames from 'classnames';
import Card from 'components/Card/Card';
import Table from 'components/Table';
import { useCallback, useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { userService } from 'services';
import { getFullName } from 'utils';
import { USER_STATUSES } from '../Users';
import useAsyncFn from 'hooks/useAsyncFn';
import useTablePagination from 'hooks/useTablePagination';

const { Search } = Input;

export function ExternalUsers() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const users = useTablePagination({
    url: '/users/admin/providers/search',
    params: { query: searchQuery },
  });

  const onChange = useCallback(
    (pagination, filters, sorter) => {
      const params = { query: searchQuery };
      if (sorter.field === 'name') {
        params.sort =
          sorter.order === 'ascend' ? ['firstName,asc', 'lastName,asc'] : ['firstName,desc', 'lastName,desc'];
      }

      users.onChange(pagination, filters, sorter, params);
    },
    [searchQuery, users],
  );

  const resetPassword = useCallback(async (user) => {
    try {
      setLoading(true);
      await userService.resetPassword(user.username);
      message.success('Password reset link has been sent to your email.', 5);
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to reset password.', 3);
    } finally {
      setLoading(false);
    }
  }, []);

  const [{ loading: sendingEmail }, resendEmail] = useAsyncFn(async (_email) => {
    try {
      await userService.resendConfirmationEmail({ username: _email });
      message.success('Confirmation email has been sent again', 5);
    } catch (error) {
      message.error(error?.apierror?.message || error?.message || 'Unable to resend confirmation email.', 5);
      newrelic.noticeError(error);
    }
  }, []);

  const [{ loading: sendingInvite }, resendInvite] = useAsyncFn(async (_user) => {
    try {
      await userService.resendInvite({ email: _user.username, roles: _user.roles });
      message.success('Invite has been sent again', 5);
    } catch (error) {
      message.error(error?.apierror?.message || error?.message || 'Unable to resend invitation email.', 5);
      newrelic.noticeError(error);
    }
  }, []);

  const allColumns = [
    {
      title: 'ID',
      skip: true,
      dataIndex: 'id',
      key: 'id',
      width: 85,
    },
    {
      title: 'Name',
      skip: true,
      headerText: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_value, record) => {
        return <span className="font-medium text-md">{getFullName(record)}</span>;
      },
      width: 250,
    },
    {
      title: 'Email',
      headerText: 'Email',
      dataIndex: ['username'],
      key: 'username',
      width: 200,
      render: (username) => {
        return <span className="font-medium">{username}</span>;
      },
    },

    {
      title: 'Roles',
      skip: true,
      headerText: 'Roles',
      dataIndex: ['roles'],
      key: 'roles',
      className: 'truncate max-w-xs',
      render: (roles) => {
        roles = roles.join(', ');
        return <span title={roles}>{roles || 'NA'}</span>;
      },
      width: 160,
    },
    {
      title: 'Actions',
      headerText: 'Actions',
      key: 'actions',
      width: 100,
      maxWidth: 100,
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <>
          <div className="flex space-x-2 items-center">
            <Tooltip title="Reset password">
              <Button
                icon={<Password />}
                disabled={loading}
                onClick={() => resetPassword(record)}
                aria-label="Reset password"
              />
            </Tooltip>

            <Tooltip title="Edit">
              <Button
                icon={<AiOutlineEdit />}
                data-testid={`edit-user-${record.id}`}
                className="icon-btn"
                onClick={() => history.push(`/admin/update-user/${record.username}`)}
                aria-label="Edit"
              />
            </Tooltip>

            <Tooltip title="Reset">
              <Button
                icon={<Reset />}
                className="icon-btn"
                onClick={() => {
                  resendInvite(record);
                }}
                disabled={USER_STATUSES.FORCE_CHANGE_PASSWORD !== record.userStatus}
                loading={sendingInvite}
                aria-label="Reset"
              />
            </Tooltip>

            <Tooltip title="Resend verification email">
              <Button
                icon={<Email />}
                className="icon-btn"
                onClick={() => {
                  resendEmail(record.username);
                }}
                loading={sendingEmail}
                disabled={record.userStatus !== USER_STATUSES.UNCONFIRMED}
                aria-label="Resend verification email"
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <Card
        noBodyPadding
        title={
          <>
            {users?.pagination?.total || 0} external user{(users?.pagination?.total || 0) !== 1 ? 's' : ''}
          </>
        }
        extra={
          <div className="flex flex-row space-x-3">
            <Button
              type="primary"
              icon={<UserFollow />}
              data-testid="add-user"
              onClick={() => history.push('/admin/add-user')}
            >
              Add user
            </Button>

            <Search
              allowClear
              placeholder="Username/email"
              onSearch={(_query) => {
                setSearchQuery(_query);
                users.fetchMore({ current: 1, pageSize: users.pagination.pageSize, query: _query?.trim?.() });
              }}
              enterButton
              data-testid="search-input"
              style={{ width: 200 }}
            />
          </div>
        }
      >
        <Table
          showColSeparator={false}
          rowKey="id"
          className={classNames('w-full')}
          allColumns={allColumns}
          loading={users.loading}
          data={users.data}
          pagination={users.pagination}
          onChange={onChange}
        />
      </Card>
    </div>
  );
}
